/* eslint react/prop-types: 0, react/display-name: 0 */
import React from 'react'
import PubSub from 'pubsub-js'
import { Layout } from './src/components/Layout'
import { LocaleWrapper } from './src/contexts/LocaleWrapper'

// const _wrapRootElement = ({ element }) => {
//   return (
//     <>
//       <Helmet>
//         <script src="https://unpkg.com/pace-js@1.0.2/pace.min.js"></script>
//       </Helmet>
//       {element}
//     </>
//   )
// }

const _wrapPageElement = ({ element, props }) => {
  // console.log(Layout)

  return (
    <LocaleWrapper>
      <Layout {...props}>{element}</Layout>
    </LocaleWrapper>
  )
}

const _onClientEntry = () => {
  const isTouch = 'ontouchstart' in window ? true : false

  if (isTouch) {
    document.documentElement.classList.add('touch')
  } else {
    document.documentElement.classList.add('no-touch')
  }

  // setTimeout(() => {
  //   document.documentElement.classList.add("is-ready")
  // }, 300)
}
const _onPreRouteUpdate = ({ location }) => {
  //console.log('new pathname', location.pathname)
  PubSub.publish('PRE_ROUTE_UPDATE')
}

const _onRouteUpdate = ({ location }) => {
  //console.log('new pathname', location.pathname)
  PubSub.publish('ROUTE_UPDATE')
  window.scrollTo(0, 0)
  setTimeout(() => {
    PubSub.publish('POST_ROUTE_UPDATE')
  }, 300)
}

const _onRenderBody = ({ setHtmlAttributes, setBodyAttributes }) => {
  setHtmlAttributes({ lang: 'de' })
  setBodyAttributes({
    className: 'is-loading',
  })
}

export {
  // _wrapRootElement,
  _wrapPageElement,
  _onClientEntry,
  _onRouteUpdate,
  _onPreRouteUpdate,
  _onRenderBody,
}
