import React from 'react'
import PortableText from '@sanity/block-content-to-react'
import MediaCard from './ui/MediaCard'
import { Link } from 'gatsby'
import { _linkResolver } from '../core/utils'
// import Figure from './ui/Figure'
// import CardMap from './ui/cards/CardMap'
// import ButtonImage from './ui/buttons/ButtonImage'
// import PeopleGroup from './PeopleGroup'
// import { _localizeField } from '../core/utils'

interface Props {
  input: any
  active?: boolean
}

const SanityTexte = ({ input, active = false }: Props) => {
  // console.log(input)
  const serializers = {
    types: {
      block(props) {
        // console.log(props.node.style)
        switch (props.node.style) {
          case 'h2':
            return <h2>{props.children}</h2>
          case 'text-lg':
            return <p className="text-lg">{props.children}</p>
          case 'text-sm':
            return <p className="text-sm">{props.children}</p>
          default:
            return <p>{props.children}</p>
        }
      },
      mediaCard: ({ node }) => {
        return <MediaCard input={node} active={active} />
      },
      // figure: ({ node }) => (
      //   <div className="mb-2">
      //     <Figure input={node} />
      //   </div>
      // ),
      // cardMap: ({ node }) => (
      //   <div className="mb-2">
      //     <div className="container">
      //       <CardMap input={node} />
      //     </div>
      //   </div>
      // ),
      // // location: ({ node }) => <pre>{JSON.stringify(node)}</pre>,
      // location: ({ node }) => (
      //   <div className="mb-2">
      //     <div className="container">
      //       <ButtonImage label={_localizeField(node.title)} />
      //     </div>
      //   </div>
      // ),
      // peopleGroup: ({ node }) => (
      //   <div className="mb-2">
      //     <div className="container">
      //       <PeopleGroup
      //         label={_localizeField(node.label)}
      //         people={node.people}
      //       />
      //       {/* <pre>{JSON.stringify(node, false, 4)}</pre> */}
      //     </div>
      //   </div>
      // ),
    },

    marks: {
      sup: ({ children }) => <sup>{children}</sup>,
      internalLink: ({ mark, children }) => {
        // console.log(children, mark);
        return <Link to={_linkResolver(mark.reference)}>{children}</Link>
      },
      link: ({ mark, children }) => {
        const { href } = mark
        return (
          <a
            href={href}
            target="_blank"
            rel="noopener, noreferrer"
            className=""
          >
            {children}
          </a>
        )
      },
    },
  }

  return (
    <div className="texte">
      <PortableText blocks={input} serializers={serializers} />
    </div>
  )
}

export default SanityTexte
